const API_ENDPOINTS = {
  auth: {
    REGISTER: "/auth/register",
    LOGIN: "/auth/login",
  },
  user: {
    LIST: "/business/users",
    USER_CREATE: "/business/users/add",
  },
  sample: {
    LIST: "/samples",
    LIST_GENERATED: "/samples/generated",
    ASSIGN_TEST_TYPE: "sample/assign",
    SEARCH: "/samples/search",
    SINGLE_SAMPLE: "/sample",

    /*New API */
    FILTER: "/samples/filter",
    SAMPLE_NAME: "/samples/names",
  },
  test_type: {
    LIST: "/testtypes",
  },
  coc: {
    LIST: "/cocs",
    SEARCH: "/cocs/search",
    COC_CREATE: "/coc",
    SINGLE_COC: "/coc",
    UPLOAD_FILE: "/coc/upload-file-url",
    FILE_DETAIL: "/coc/detail/",
    ACCEPT_MAPPING: "/coc/mapping/accept",
    REJECT_MAPPING: "/coc/mapping/reject/",
    GET_REPORT_PARAMETERS: "/coc/:id/report/paramaters",

    /*New API */
    FILTER: "/coc/filter",
    GET_MAPPING: "coc/mapping/:id",
    PRINTED_COC: "benefitrealization",
  },
  code: {
    LIST: "/codes",
    LIST_ADDITIONAL: "/additionalcode/region",
  },
  parameter: {
    LIST: "/criterias",
    LIST_OF_REGION: "/criterias/region",
  },
  file: {
    LIST: "/noncoc/files",
    UPLOAD_FILE: "/noncoc/upload-file-url",
    REMOVE_FILE: "/noncoc/:fileid",
    GENERATE_REPORT: "/noncoc/:fileid/report/generate",
    TRANSFORM_FILE: "/noncoc/detail/:filename/:uploadby",
    DETAIL_FILE: "/noncoc/download/:fileid",
    GET_REPORT_PARAMETERS: "/noncoc/:fileid/report/paramaters",
    GET_FORM_LAB_FILE: "/noncoc/:fileid/form",
    SAVE_FORM_LAB_FILE: "/noncoc/:fileid/form",
    GET_FILE_SAMPLES: "/noncoc/:fileid/samples",
    GET_REPORT_PARAMETERS_TIME_OUT_COC: "/notification/coc/report",
    GET_REPORT_PARAMETERS_TIME_OUT: "/notification/non-coc/report",
  },
  photo: {
    LIST_IN_COC: "/photo/:id/coc",
    LIST: "/photo/search",
    SEARCH: "/photo/search",
    DETAIL_PHOTO: "/photo/download",
    EXPORT_PHOTO: "photo/export_docx",

    /*New API */
    FILTER: "/photo/filter",
  },
  notification: {
    LIST: "/notification",
    DETAIL_NOTIFICATION: "/notification/:id",
    MARK_NOTIFICATION: "/notification/:id/mark",
  },
  fieldObservations: {
    LIST: "/soil-logging/search",
    LIST_PDF: "/soil-logging/export-pdf",
  },
  groundWaterLevel: {
    LIST: "/gwl/fieldsheet/search",
    LIST_PROJECT_NUMBER: "/gwl/fieldsheet/project_numbers",
    LIST_BOREHOLE_ID: "/gwl/fieldsheet/list/",
    LIST_LEVEL_LOGGER: "/gwl/levellogger/search",
    UPLOAD_LEVEL_LOGGER: "/gwl/levellogger/upload",
    GET_PROJET_CORE_GS: "/coregs/filter",
    GET_BOREHOLEID_CORE_GS: "/coregs/get",
    CREATE_NEW_SCHEDULE_FIELD: "/gwl/fieldsheet/create",
    GET_FIELD_SHEET_DATA: "/gwl/fieldsheet/get",
    GET_FIELD_SHEET_DATA_GRAPH: "/gwl/fieldsheet/graph",
    GET_DATA_LEVEL_LOGGER: "/gwl/levellogger",
    DOWNLOAD_FILE_LEVEL_LOGGER: "/gwl/levellogger/download",
    DELETE_DATA_LEVEL_LOGGER: "/gwl/levellogger/delete",
    GET_DATA_MASTER_TABLE: "/gwl/transform",
    GET_DATA_MASTER_TABLE_TIME_OUT: "/gwl/master-table/json",
    GET_VISIT_DATA: "gwl/extract",
    EXPORT_XLSX_DATA_MASTER_TABLE: "/gwl/export/xlsx",
    EXPORT_XLSX_DATA_MASTER_TABLE_TIME_OUT: "/gwl/master-table/xlsx",
    GET_RAINFALL_LIST: "/gwl/rainfall/search",
    DELETE_DATA_RAIN_FALL: "/gwl/rainfall/delete",
    DOWNLOAD_FILE_RAIN_FALL: "/gwl/rainfall/download",
    UPLOAD_RAIN_FALL: "/gwl/rainfall/upload",
    GET_DATA_RAIN_FALL_PIEZOMETER: "/gwl/rainfall",
    DOWNLOAD_FILE_FIELD_DATA: "/gwl/fieldsheet/xlsx",
    DOWNLOAD_FILE_FIELD_DATA_PDF: "/gwl/fieldsheet/pdf",
    GET_DATA_RESULT_DATA: "/gwl/summary-table/search",
    DOWNLOAD_FILE_RESULT_TABLE: "/gwl/summary-table/export/xlsx",
    DOWNLOAD_FILE_RESULT_TABLE_PDF: "/gwl/summary-table/export/pdf",
    APPROVE_RESULT_TABLE_DATA: "/gwl/summary-table/approve-data",
    UNAPPROVE_RESULT_TABLE_DATA: "/gwl/summary-table/unapproved-data",
    GROUP_REVIEWER_DATA: "/auth/retrieve-user-info",
    APPROVE_MASTER_TABLE_DATA: "/gwl/master-table/approve-data",
    UNAPPROVE_MASTER_TABLE_DATA: "/gwl/master-table/unapproved-data",
    GET_MASTER_TABLE_REVIEWER_DATA: "/gwl/master-table/review-info",
    CREATE_PIZOMETERS: "/gwl/manual-piezometer/create",
    GET_DATA_ATTRIBUTES: "/gwl/coregs/get",
    UPDATE_ATTRIBUTES: "/gwl/coregs/update",
  },
  waterQuantity: {
    GET_WATER_QUALITY: "/wq/sample/search",
    GET_DETAIL_WATER_QUALITY: "/wq/sample",
    UPDATE_DETAIL_WATER_QUALITY: "/wq/sample",
    GET_ASSIGN_TEST: "/wq/test-types",
    UPDATE_ASSIGN_TEST: "/wq/sample/assign-test",
    FIELD_PARAMETER_EXPORT: "/wq/field-parameter/export",
    UPDATE_COC: "/wq/coc",
    GET_LIST_COMPANY: "/wq/code/Company",
    GET_LIST_LAB: "wq/code/Lab",
    GET_COC: "/wq/coc",
    GET_COC_LIST: "/wq/coc/search",
    DOWNLOAD_FIELD_SHEET: "/wq/field_sheet/export-gw",
    GET_COC_LIST_WQ: "/wq/photo/search",
    DOWNLOAD_PHOTO: "/wq/photo/download",
    EXPOPRT_PHOTO: "/wq/photo/export",
    GET_DATA_EXPORT_GW: "/wq/field_sheet/export-data-gw",
    GET_DATA_EXPORT_SW: "/wq/field_sheet/export-data-sw",
    UPLOAD_COC: "/wq/coc/upload",
    DELETE_COC: "/wq/coc/delete",
    GET_DATA_RESULT_TABLE_COC: "/wq/coc/files",
    DOWNLOAD_COC: "/wq/coc/download",
    GET_DATA_RESULT_TABLE_WITHOUT_COC: "/wq/noncoc/files",
    UPLOAD_NON_COC: "/wq/noncoc/upload",
    DELETE_NON_COC: "/wq/noncoc/delete",
    DOWNLOAD_NON_COC: "/wq/noncoc/download",
    EXPORT_COC: "/wq/coc/export/xlsx",
    EXPORT_NON_COC: "/wq/noncoc/export/xlsx",
    GET_DATA_FIELD_SHEET_PARAMETERS: "/wq/field-sheet/search",
    GET_DATA_CRITERIA: "/wq/criteria/list",
    REPORT_PARAMETER: "/wq/criteria/coc/parameter",
    REPORT_PARAMETER_WITHOUT_COC: "/wq/criteria/file/parameter",
    ACCEPT_IMPORT: "/wq/coc/accept/import",
    GET_MAPPING_SAMPLE_NAME: "wq/coc/get/mapping",
    REJECT_FILE_IMPORT: "/wq/coc/reject/mapping",
    UPLOAD_MAPPING: "wq/coc/update/mapping",
    CREATE_LAB_FORM: "/wq/lab-form",
    GET_LAB_FORM: "/wq/lab-form/file-id",
    LAB_FORM_PDF: "/wq/lab-form/pdf",
    GET_MAPPING: "/wq/coc/get/mapping",
    GET_COC_SAMPLES: "/wq/coc/samples",
    GET_RESULT_TABLE_WITHOUTCOC: "/wq/noncoc/result-table",
    GET_RESULT_TABLE_WITHCOC: "/wq/coc/result-table",
  },
  manualDip: {
    GET_MANUAL_DIP: "/swl/fieldsheet/search",
    LIST_PROJECT_NUMBER: "/swl/fieldsheet/project_numbers",
    LIST_BOREHOLE_ID: "/swl/fieldsheet/list",
    CREATE_NEW_SCHEDULE_FIELD: "/swl/fieldsheet/create",
    GET_FIELD_SHEET_DATA: "/swl/fieldsheet/get",
    GET_FIELD_SHEET_DATA_GRAPH: "/swl/fieldsheet/graph",
    DOWNLOAD_FILE_FIELD_DATA: "/swl/fieldsheet/xlsx",
    GET_MASTER_TABLE: "/swl/transform",
    GET_MASTER_TABLE_REVIEWER_DATA: "/swl/master-table/review-info",
    DOWNLOAD_EXCEL: "/swl/export/xlsx",
    APPROVE_MASTER_TABLE_DATA: "/swl/master-table/approve-data",
    UNAPPROVE_MASTER_TABLE_DATA: "/swl/master-table/unapproved-data",
    GET_DATA_RESULT_DATA: "/swl/summary-table/search",
    APPROVE_RESULT_TABLE_DATA: "/swl/summary-table/approve-data",
    UNAPPROVE_RESULT_TABLE_DATA: "/swl/summary-table/unapproved-data",
    GET_DETAIL_SUMMARY: "/swl/summary-table/detail",
    DOWNLOAD_FILE_RESULT_TABLE: "/swl/summary-table/export/xlsx",
    DOWNLOAD_FILE_RESULT_TABLE_PDF: "/swl/summary-table/export/pdf",
    CREATE_PIZOMETERS: "/swl/manual-piezometer/create",
    GET_DATA_ATTRIBUTES: "/swl/coregs/get",
    UPDATE_ATTRIBUTES: "/swl/coregs/update",
  },
  map: {
    GET_LOCATION: "/gis/get/locations",
    GET_LOCATION_DETAIL: "/gis/get/detail",
  },
  landFillGas: {
    WELL_DATA_GET_BY_SITE_NAME: "/land-fill/well-data/get/",
    WELL_DATA_GET_SITE_NAME: "/land-fill/well-data/get/site-names",
    WELL_DATA_SEARCH: "/land-fill/well-data/search",
    WELL_DATA_TRANSFORM: "/land-fill/well-data/transform",
    WELL_DATA_IMPORT: "/land-fill/well-data/import",
    WELL_DATA_EXPORT: "/land-fill/well-data/export",

    WELL_DATA_GET_RAW_DATA: "/land-fill/well-data/get-raw-data/",
    WELL_DATA_UPDATE_WELL_GAS_DATA: '/land-fill/well-data/update-well-gas-data',

    WELL_DATA_GET_ATTRIBUTE_DATA: "/land-fill/well-data/get-attribute-data/",
    WELL_DATA_UPDATE_ATTRIBUTE_DATA: '/land-fill/well-data/update-well-attribute-data',


    WELL_DATA_DELETE: '/land-fill/well-data/delete',
    WELL_DATA_GET_SURVEY_DATA: '/land-fill/well-data/get-survey-data/',
    WELL_DATA_UPDATE_SURVEY_DATA: '/land-fill/well-data/update-survey-data',

    WELL_PHOTO_SEARCH: "/land-fill/well-photo/search",
    WELL_PHOTO_DOWNLOAD: "/land-fill/well-photo/download",
    WELL_PHOTO_EXPORT: "/land-fill/well-photo/export",
    WELL_PHOTO_EXPORT_PDF: "/land-fill/well-photo/export-pdf",

    WELL_MANUAL_IMPORT: "land-fill/manual-data/import",
    WELL_MANUAL_SEARCH: "land-fill/manual-data/search",
    WELL_MANUAL_DELETE: "land-fill/manual-data/delete/",
    WELL_MANUAL_DOWNLOAD: "land-fill/manual-data/download/",

    WELL_GENERATE_RESULT_TABLE: 'land-fill/well-table/get-data',
    WELL_EXPORT_EXCEL_RESULT_TABLE: 'land-fill/well-table/export/xlsx',
    WELL_EXPORT_PDF_RESULT_TABLE: 'land-fill/well-table/export/pdf',

    VI_PHOTO_SEARCH: "land-fill/vi-photo/search",
    VI_PHOTO_DOWNLOAD: "land-fill/vi-photo/download",
    VI_PHOTO_EXPORT: "/land-fill/vi-photo/export",
    VI_PHOTO_EXPORT_PDF: "/land-fill/vi-photo/export-pdf",

    VI_COMMNENT_SEARCH: "land-fill/vi-comment/search",
    GET_SITENAMES_VI_COMMENT: "land-fill/vi-comment/get/site-names",
    VI_COMMENT_DATA_DELETE: '/land-fill/vi-comment/delete',
    VI_EXPORT_DOCX: '/land-fill/vi-comment/export/docx',
    VI_EXPORT_PDF: '/land-fill/vi-comment/export-pdf',
    VI_VALID_DATA_GENERATE: '/land-fill/well-table/validate'
  }
};

export default API_ENDPOINTS;
